




import { Component, Vue } from 'vue-property-decorator'
import RecommendationDetail from '@/views/recommendation/components/RecommendationDetail.vue'

@Component({
  name: 'CreateClient',
  components: {
    RecommendationDetail
  }
})
export default class extends Vue {}
