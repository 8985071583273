import request from '@/utils/request'
import { exportJson2Excel, prepareExcelHeaders } from '@/utils/excel'
import { formatJson, getTranslations } from '@/utils'

export const getTickets = (data: any) =>
  request({
    method: 'post',
    url: '/api/tickets/getTickets',
    data
  })

export const getTicketsCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/tickets/getTicketsCount',
    data
  })

export const getTicket = (params: any) =>
  request({
    method: 'get',
    url: '/api/tickets/getTicket',
    params
  })

export const saveTicket = (data: any) =>
  request({
    method: 'post',
    url: '/api/tickets/saveTicket',
    data
  })

export const addMessage = (data: any) =>
  request({
    method: 'post',
    url: '/api/tickets/addMessage',
    data
  })

export const saveTicketsWithMedia = (formData: any) =>
  request.post('/api/tickets/saveTicketsWithMedia', formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

export const saveTicketBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/tickets/saveTicketBulk',
    data
  })

export const deleteTicket = (params: any) =>
  request({
    method: 'get',
    url: '/api/tickets/deleteTicket',
    params
  })

export const deleteTicketBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/tickets/deleteTicketBulk',
    data
  })

export const exportTickets = (clients: any[]) => {
  const fields = [
    { key: 'id' },
    { key: 'date_created', type: 'timestamp' }
  ]

  return exportJson2Excel(
    prepareExcelHeaders(fields),
    formatJson(fields, clients),
    'ticket-list'
  )
}
