

















































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  addMessage,
  deleteTicket,
  getTicket,
  saveTicketsWithMedia
} from '@/api/tickets'
import Sticky from '@/components/Sticky/index.vue'
import Tinymce from '@/components/Tinymce/index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule, DeviceType } from '@/store/modules/app'
import { confirmDialog, errorMsg, getTranslations, parseTime, successMsg, validateForm } from '@/utils'
import { Form } from 'element-ui'
import { Ticket } from '@/models/Ticket'

@Component({
  name: 'TicketDetail',
  components: {
    Sticky,
    Tinymce,
    ImageGallery: () => import('@/views/property/components/ImageGallery.vue')
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isEdit!: boolean
  private skeletonLoader = false
  private loading = false
  private user: string | null = null
  private files: any = null
  private message = ''
  private parseTime = parseTime
  private ticket = new Ticket({
    office_id: UserModule.officeId,
    user_id: UserModule.id,
    status: 'open',
    meta: { attachments: [], messages: [] }
  })

  private rules = {
    type: {
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    },
    status: {
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    },
    severity: {
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    },
    'meta.summary': {
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    }
  }

  created() {
    if (this.isEdit && this.$route.params && this.$route.params.id) {
      this.getItem(this.$route.params.id)
    }
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private handleOnChange(file: Object, fileList: Object[]) {
    this.files = fileList
  }

  private async addMessage() {
    if (!this.message) {
      return
    }
    try {
      this.ticket.meta.messages.push({
        user_id: UserModule.id,
        full_name: getTranslations(UserModule),
        content: this.message,
        date_created: new Date().getTime()
      })
      await addMessage({
        id: this.ticket.id,
        messages: this.ticket.meta.messages
      })
      this.message = ''
    } catch (e) {
      await errorMsg('api.serverError')
    }
  }

  private getImage(image: any) {
    return `/static/tickets/${this.ticket.id}/attachments/${image.name}`
  }

  private async getItem(id: string) {
    try {
      this.skeletonLoader = true
      const { data } = await getTicket({ id })
      data.meta.messages = data.meta.messages || []
      this.ticket = data
      this.user = getTranslations(this.ticket.user)
    } catch (err) {
      await errorMsg('api.entityNotFound')
    } finally {
      this.skeletonLoader = false
    }
  }

  private async deleteItem() {
    try {
      const [data] = await confirmDialog('actions.apiDelete')
      if (!data) return
      await deleteTicket({ id: this.ticket.id })
      await successMsg('actions.apiDeleteSuccess')
      return this.$router.push('/ticket/list')
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private prepareTicket() {
    const formData = new FormData()
    formData.append('ticket', JSON.stringify(this.ticket))
    if (this.files && this.files.length) {
      for (let i = 0; i < this.files.length; i++) {
        if ((this.files[i].raw)) {
          formData.append('attachments', this.files[i].raw)
        }
      }
    }
    return formData
  }

  private normalizeItemForSave() {
    this.ticket.user = null
    this.ticket.user_id = this.ticket.user_id || UserModule.id
    this.ticket.owner = this.ticket.owner || UserModule.id
  }

  private getSrcList(attachments: any) {
    return attachments.map((attachment: any) => {
      return this.getImage(attachment)
    })
  }

  private async saveItem() {
    try {
      const [isValid] = await validateForm(this.$refs.form as Form)
      if (!isValid) return errorMsg('form.formErrors')
      this.loading = true
      this.normalizeItemForSave()
      const { data } = await saveTicketsWithMedia(this.prepareTicket())
      if (!this.isEdit) return this.$router.push('/support/ticketing/edit/' + data.id)
      await successMsg('actions.apiSuccessSave')
    } catch (err) {
      await errorMsg('api.serverError')
    } finally {
      this.loading = false
    }
  }
}
