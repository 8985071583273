/* eslint-disable camelcase */
export class Ticket {
  id: string | null;
  type: string | null;
  status: string | null;
  severity: string | null;
  user_id: string | null;
  user: string | null;
  office_id: string | null;
  owner: string | null;
  meta: any;

  constructor(data: any = {}) {
    this.id = data.id || null
    this.type = data.type || null
    this.status = data.status || null
    this.severity = data.severity || null
    this.user_id = data.user_id || null
    this.user = data.user || null
    this.office_id = data.office_id || null
    this.owner = data.owner || null
    this.meta = data.meta || {}
  }
}
